import {
  LEVEL_THEMES,
  PLACEMENT_TYPE_FLOUR,
  PLACEMENT_TYPE_GOAL,
  PLACEMENT_TYPE_HERO,
  PLACEMENT_TYPE_HERO2,
  PLACEMENT_TYPE_WALL,
  PLACEMENT_TYPE_LOCK,
  PLACEMENT_TYPE_KEY,
  PLACEMENT_TYPE_ICE,
  PLACEMENT_TYPE_WATER,
  PLACEMENT_TYPE_FIRE,
  PLACEMENT_TYPE_CONVEYOR,
  PLACEMENT_TYPE_WATER_PICKUP,
  PLACEMENT_TYPE_ICE_PICKUP,
  PLACEMENT_TYPE_ROAMING_ENEMY,
  PLACEMENT_TYPE_GROUND_ENEMY,
  PLACEMENT_TYPE_FLYING_ENEMY,
  PLACEMENT_TYPE_FIRE_PICKUP,
  PLACEMENT_TYPE_SWITCH_DOOR,
  PLACEMENT_TYPE_SWITCH,
  PLACEMENT_TYPE_TELEPORT,
  PLACEMENT_TYPE_THIEF,
  PLACEMENT_TYPE_CIABATTA,
} from "../helpers/consts";

const level = {
  theme: LEVEL_THEMES.YELLOW,
  tilesWidth: 9,
  tilesHeight: 9,
  placements: [
    { x: 1, y: 1, type: PLACEMENT_TYPE_HERO },
    { x: 2, y: 1, type: PLACEMENT_TYPE_HERO2 },
    { type: PLACEMENT_TYPE_GOAL, x: 1, y: 2 },

      { type: PLACEMENT_TYPE_WALL, x: 2, y: 2 },
      { type: PLACEMENT_TYPE_WALL, x: 8, y: 2 },
      { type: PLACEMENT_TYPE_WALL, x: 6, y: 2 },
      { type: PLACEMENT_TYPE_WALL, x: 4, y: 2 },
      { type: PLACEMENT_TYPE_WALL, x: 2, y: 4 },
      { type: PLACEMENT_TYPE_WALL, x: 4, y: 4 },
      { type: PLACEMENT_TYPE_WALL, x: 6, y: 4 },
      { type: PLACEMENT_TYPE_WALL, x: 8, y: 4 },
      { type: PLACEMENT_TYPE_WALL, x: 8, y: 6 },
      { type: PLACEMENT_TYPE_WALL, x: 6, y: 6 },
      { type: PLACEMENT_TYPE_WALL, x: 4, y: 6 },
      { type: PLACEMENT_TYPE_WALL, x: 2, y: 6 },
      { type: PLACEMENT_TYPE_WALL, x: 2, y: 8 },
      { type: PLACEMENT_TYPE_WALL, x: 4, y: 8 },
      { type: PLACEMENT_TYPE_WALL, x: 6, y: 8 },
      { type: PLACEMENT_TYPE_WALL, x: 8, y: 8 },
      { type: PLACEMENT_TYPE_FLOUR, x: 5, y: 5 },
      { type: PLACEMENT_TYPE_FLOUR, x: 3, y: 7 },
      { type: PLACEMENT_TYPE_FLOUR, x: 7, y: 7 },
      { type: PLACEMENT_TYPE_FLOUR, x: 7, y: 3 },
      { type: PLACEMENT_TYPE_FLOUR, x: 3, y: 3 },
      { type: PLACEMENT_TYPE_GROUND_ENEMY, initialDirection: "DOWN" , x: 3, y: 4 },
      { type: PLACEMENT_TYPE_GROUND_ENEMY, initialDirection: "UP" ,x: 3, y: 6 },
      { type: PLACEMENT_TYPE_GROUND_ENEMY, initialDirection: "DOWN", x: 7, y: 4 },
      { type: PLACEMENT_TYPE_GROUND_ENEMY,  initialDirection: "UP" , x: 7, y: 6 },
      { type: PLACEMENT_TYPE_GROUND_ENEMY, initialDirection: "UP" , x: 5, y: 8 },
      { type: PLACEMENT_TYPE_GROUND_ENEMY, initialDirection: "LEFT" , x: 5, y: 7 },
      { type: PLACEMENT_TYPE_GROUND_ENEMY, initialDirection: "RIGHT", x: 5, y: 3 },
      { type: PLACEMENT_TYPE_GROUND_ENEMY, initialDirection: "RIGHT", x: 3, y: 5 },
      { type: PLACEMENT_TYPE_GROUND_ENEMY, initialDirection: "LEFT", x: 7, y: 5 },
    
  ],
};

export default level;
