import { Placement } from "./Placement";
import Sprite from "../components/object-graphics/Sprite";

import {
  BODY_SKINS,
  BODY_SKINS2,
  PLACEMENT_TYPE_HERO,
  PLACEMENT_TYPE_HERO2,
  PLACEMENT_TYPE_WATER_PICKUP,
} from "../helpers/consts";

export class WaterPlacement extends Placement {
  changesHeroSkinOnCollide() {
    return BODY_SKINS.WATER;
  }
  changesHero2SkinOnCollide() {
    return BODY_SKINS2.WATER;
  }

  isSolidForBody(body) {
    return body.turnsAroundAtWater ?? false;
  }

  damagesBodyOnCollide(body) {
    
    const { inventory } = this.level;
    return (
      (body.type === PLACEMENT_TYPE_HERO || body.type === PLACEMENT_TYPE_HERO2)  &&
      !inventory.has(PLACEMENT_TYPE_WATER_PICKUP)
    );
  }

  

 // damagesBody2OnCollide(body) {
 //   
 //   const { inventory } = this.level;
 //   return (
 //     body.type === PLACEMENT_TYPE_HERO2 &&
 //     !inventory.has(PLACEMENT_TYPE_WATER_PICKUP)
 //   );
 // }

  renderComponent() {
    const waterFrame = this.level.animatedFrames.waterFrame;
    return <Sprite frameCoord={waterFrame} />;
  }
}
