import Body from "../components/object-graphics/Body";
import {
  DIRECTION_LEFT,
  DIRECTION_RIGHT,
  directionUpdateMap,
  BODY_SKINS2,
  HERO2_RUN_1,
  HERO2_RUN_2,
  Z_INDEX_LAYER_SIZE,
  PLACEMENT_TYPE_CELEBRATION,
} from "../helpers/consts";
import { TILES } from "../helpers/tiles";
import { BodyPlacement } from "./BodyPlacement";

const heroSkinMap = {
  [BODY_SKINS2.NORMAL]: [TILES.HERO2_LEFT, TILES.HERO2_RIGHT],
  [BODY_SKINS2.WATER]: [TILES.HERO2_WATER_LEFT, TILES.HERO2_WATER_RIGHT],
  [BODY_SKINS2.FIRE]: [TILES.HERO2_FIRE_LEFT, TILES.HERO2_FIRE_RIGHT],
  [BODY_SKINS2.DEATH]: [TILES.HERO2_DEATH_LEFT, TILES.HERO2_DEATH_RIGHT],
  [BODY_SKINS2.SCARED]: [TILES.HERO2_DEATH_LEFT, TILES.HERO2_DEATH_RIGHT],
  [BODY_SKINS2.ICE]: [TILES.HERO2_ICE_LEFT, TILES.HERO2_ICE_RIGHT],
  [BODY_SKINS2.CONVEYOR]: [TILES.HERO2_CONVEYOR_LEFT, TILES.HERO2_CONVEYOR_RIGHT],
  [BODY_SKINS2.TELEPORT]: [TILES.HERO2_TELEPORT_LEFT, TILES.HERO2_TELEPORT_RIGHT],
  [HERO2_RUN_1]: [TILES.HERO2_RUN_1_LEFT, TILES.HERO2_RUN_1_RIGHT],
  [HERO2_RUN_2]: [TILES.HERO2_RUN_2_LEFT, TILES.HERO2_RUN_2_RIGHT],
};

export class HeroPlacement2 extends BodyPlacement {
  constructor(properties, level) {
    super(properties, level);
    this.canCollectItems = true;
    this.canCompleteLevel = true;
    this.interactsWithGround = true;
  }

  controllerMoveRequested(direction) {
    //Attempt to start moving
    if (this.movingPixelsRemaining > 0) {
      return;
    }

    // Check for lock at next position

    const possibleLock = this.getLockAtNextPosition(direction);
    if (possibleLock) {
      possibleLock.unlock();
      return;
    }

    //Make sure the next space is available
    if (this.isSolidAtNextPosition(direction)) {
      return;
    }

    

    // Maybe hop out of non-normal skin

    if (this.skin === BODY_SKINS2.WATER) {
      const collision = this.getCollisionAtNextPosition(direction);
      if (!collision.withChangesHeroSkin()) {
        this.skin = BODY_SKINS2.NORMAL;
      }
    }

    //Start the move
    this.movingPixelsRemaining = 16;
    this.movingPixelDirection = direction;
    this.updateFacingDirection();
    this.updateWalkFrame();
  }

  onAutoMovement(direction) {
    this.controllerMoveRequested(direction);
  }

  takesDamage(deathType) {
    this.level.setDeathOutcome(deathType);
  }

  zIndex() {
    return this.y * Z_INDEX_LAYER_SIZE + 1;
  }

  getFrame() {
    //Which frame to show?
    const index = this.spriteFacingDirection === DIRECTION_LEFT ? 0 : 1;
    // If dead, show the dead skin
    if (this.level.deathOutcome) {
      return heroSkinMap[BODY_SKINS2.DEATH][index];
    }
    //Use correct walking frame per direction
    if (this.movingPixelsRemaining > 0 && this.skin === BODY_SKINS2.NORMAL) {
      const walkKey = this.spriteWalkFrame === 0 ? HERO2_RUN_1 : HERO2_RUN_2;
      return heroSkinMap[walkKey][index];
    }
    return heroSkinMap[this.skin][index];
  }

  canBeDeleted() {
    return false;
  }


  renderComponent() {
    const showShadow = this.skin !== BODY_SKINS2.WATER;
    return (
      <Body
        frameCoord={this.getFrame()}
        yTranslate={this.getYTranslate()}
        showShadow={showShadow}
      />
    );
  }
}
