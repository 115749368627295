import {
  DIRECTION_UP,
  DIRECTION_DOWN,
  DIRECTION_RIGHT,
  DIRECTION_LEFT,
} from "../helpers/consts";

export class DirectionControls2 {
  constructor() {
    this.directionKeys2 = {
      ArrowDown: DIRECTION_DOWN,
      ArrowUp: DIRECTION_UP,
      ArrowLeft: DIRECTION_LEFT,
      ArrowRight: DIRECTION_RIGHT,
    // s: DIRECTION_DOWN,
    // w: DIRECTION_UP,
    // a: DIRECTION_LEFT,
    // d: DIRECTION_RIGHT,
    };

    this.heldDirections2 = [];

    this.directionKeyDownHandler2 = (e) => {
      const dir = this.directionKeys2[e.key];

      if (dir && this.heldDirections2.indexOf(dir) === -1) {
        this.heldDirections2.unshift(dir);
      }
    };

    this.directionKeyUpHandler2 = (e) => {
      const dir = this.directionKeys2[e.key];
      const index = this.heldDirections2.indexOf(dir);

      if (index > -1) {
        this.heldDirections2.splice(index, 1);
      }
    };

    document.addEventListener("keydown", this.directionKeyDownHandler2);
    document.addEventListener("keyup", this.directionKeyUpHandler2);
  }

  get direction() {
    return this.heldDirections2[0];
  }

  unbind() {
    document.removeEventListener("keydown", this.directionKeyDownHandler2);
    document.removeEventListener("keyup", this.directionKeyUpHandler2);
  }
}
