// import DemoLevel1 from "./DemoLevel1";
// import DemoLevel2 from "./DemoLevel2";
import Level11 from "./Level11";
import Level12 from "./Level12";
import Level13 from "./Level13";
import Level14 from "./Level14";
import Level15 from "./Level15";
import Level21 from "./Level21";
import Level22 from "./Level22";
import Level23 from "./Level23";
import Level24 from "./Level24";
import Level25 from "./Level25";
import Level31 from "./Level31";
import Level32 from "./Level32";
import Level33 from "./Level33";
import Level41 from "./Level41";
import Level42 from "./Level42";

const Levels = {
 
  //level 1-1 / 1-5
  DemoLevel1: Level11,
  DemoLevel2: Level12,
  DemoLevel3: Level13,
  DemoLevel4: Level14,
  DemoLevel5: Level15,

  //level 2-1 / 2-5
  DemoLevel6: Level21,
  DemoLevel7: Level22,
  DemoLevel8: Level23,
  DemoLevel9: Level24,
  DemoLevel10: Level25,

  //level 3-1 / 3-5
  DemoLevel11: Level31,
  DemoLevel12: Level32,
  DemoLevel13: Level33,

  //level 4-1 / 4-5
  DemoLevel14: Level41,
  DemoLevel15: Level42,
};

export default Levels;
